<template>
  <div>
    <Header>Suivi des vacants</Header>
    <BienImmos ref="bienimmos" :type="type" />
  </div>
</template>

<script>
import { biListType } from '@/utils/crm/interfaces';
import BienImmos from "@/views/bien-immo/BienImmos.vue";

export default {
  components: {
    BienImmos,
  },
  data() {
    return {
      type: biListType.vacants,
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$refs.bienimmos.routeEntered();
    });
  },
}
</script>
